/** This is For Dashbaord **/
.dashboardWrapper {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  display: block;
  .leftInfo {
    margin: 10px 0px 0px 0px;
    padding: $leftPadding;
    // width: $leftWidth;
    position: absolute;
    z-index: 1;
    background: $leftbachgound;
    height: 100%;
  }

  .leftInfoStates {
    margin: 53px 0px 0px 285px;
    padding: $leftPadding;

    position: absolute;
    z-index: 1;
    background: $leftbachgound;
    height: 100%;
    min-height: 50;
  }

  .rightInfo {
    margin: 10px;
    position: relative;
    z-index: 1;
    background: $leftbachgound;
    height: 100%;
    float: right;
  }

  .banner {
    position: absolute;
    z-index: 10000;
    background: $wBackground;
    color: #fff;
    float: left;
    margin-top: 3%;
    margin-left: 84%;
    opacity: 0.4;
  }
}
