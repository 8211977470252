.boxWidget {
  margin-bottom: $wMarginBtm;
  min-height: $wHeight;
  width: 100%;
  background: $wBackground;
  border-radius: 10px;
  border: $wBorder;
  .title,
  .content,
  .footer {
    width: 100%;
    color: #fff;
    text-align: center;
  }
  .title {
    font-size: $wTitleSize;
  }
  .content {
    font-size: $wValueSize;
    top: 45%;
  }
  .footer {
    font-size: $wFooterSize;
    position: absolute;
    bottom: 5px;
  }
}
.widget_distance {
  .content {
    color: $wValueDistance;
  }
}

.widget_moving {
  .content {
    color: $wValueMoving;
  }
}

.widget_stationary {
  .content {
    color: $wValueStationary;
  }
}

.widget_stopped {
  .content {
    color: $wValueStopped;
  }
}
