/** This is for map ***/
.mapWrapper {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
  .mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
    background-color: grey !important;
  }

  .marker-vehicle-moving {
    background-image: url("/images/truckfrontmoving.png");
    background-size: cover;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
  }

  .marker-vehicle-stopped {
    background-image: url("/images/truckfrontstopped.png");
    background-size: cover;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
  }

  .marker-vehicle-stationary {
    background-image: url("/images/truckfrontstationary.png");
    background-size: cover;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
  }
}
