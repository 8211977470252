@import "variables";
body.body_default {
  overflow: hidden;
  .app_default {
    padding: 0px;
    margin: 0px;
    display: block;
    @import "dashboard", "map", "widget";
  }
}
