/** Height & Widht **/

/*** Background & Color ****/
$background: #ffffff;

/** Dash Baord */
$leftPadding: 30px;
$leftWidth: 350px;
$leftbachgound: rgba(0, 0, 0, 0);

/** Widget Style **/
$wMarginBtm: $leftPadding;
$wTitleSize: 18px;
$wValueSize: 50px;
$wFooterSize: 16px;
$wHeight: 108px;
$wBackground: linear-gradient(#1b5a92, #1b5a92); //#2f80c9
$wBorder: 0px solid rgba(5, 10, 15, 0.15);
$wValueDistance: #ffffff;
$wValueMoving: #5dfa3f;
$wValueStationary: orange;
$wValueStopped: red;
